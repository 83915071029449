import React from "react"
import { Box } from "theme-ui"

const CardBox = ({ children, style }) => {
  return <Box sx={{ ...boxStyle, ...style }}>{children}</Box>
}

export default CardBox

const boxStyle = {
  padding: [3, 4],
  backgroundColor: "isWhite",
  borderRadius: "3px",
  maxWidth: ["none", "none", 400],
  border: "5px solid",
  borderColor: "isWhite",
  borderBottom: "5px solid #20bed6",
  transition: "all .5s",

  "&:hover": {
    border: "5px solid",
    borderColor: "primary",
    transition: "all .5s",
    cursor: "pointer",
  },
}
