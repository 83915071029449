import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import FeaturedArticle from "../components/FeaturedArticle"
import Card from "../components/BlogCard"
import { Box, Flex, Heading } from "theme-ui"
import Container from "../components/Container"

const BlogIndex = ({ data, location }) => {
  // Get the first article which will be the one displayed @ top of HP
  const firstArticle = data.allBlog.nodes[0]
  const featuredArticleData = {
    tags: firstArticle.tags,
    title: firstArticle.title,
    desc: firstArticle.description,
    author: firstArticle.author,
    createdAt: firstArticle.createdAt,
    image: firstArticle.media,
  }

  const renderArticles = () =>
    data.allBlog.nodes.map(art => (
      <Box
        key={art.title}
        sx={{ width: ["100%", "100%", "50%", "50%", "33%"], padding: 3 }}
      >
        <Card data={art} />
      </Box>
    ))

  return (
    <Layout location={location} title={"GoNation Newsroom"}>
      <Seo
        title={"Home"}
        description={
          "Welcome to the GoNation Newsroom. A place to stay up to date with the new things happening at GoNation. Get the latest news, updates and announcements here!"
        }
        img="https://res.cloudinary.com/gonation/image/upload/v1630710595/gn_assets/cities.jpg"
      />
      <Box sx={{ mt: [3, 3, 0] }}>
        <FeaturedArticle data={featuredArticleData}></FeaturedArticle>
      </Box>
      <Container width="1200px">
        <Heading
          sx={{
            pl: 3,
            fontSize: [5, 5, 5, 5, 6, 8],
            fontWeight: 400,
            mt: 5,
            mb: [3, 3, 3, 4],
            borderBottom: "3px solid",
            borderColor: "primary",
          }}
          as="h2"
        >
          Latest News
        </Heading>
        <Flex sx={{ flexWrap: "wrap", pb: [3, 3, 3, 4] }}>
          {renderArticles()}
        </Flex>
      </Container>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    allBlog {
      nodes {
        articleType
        author
        body
        bodyHTML
        bodyPlainText
        createdAt
        description
        ctaLink {
          title
          url
        }
        image {
          image {
            cloudinaryId
            id
          }
        }
        media {
          cloudinaryId
          description
          name
        }
        name
        readingTime
        readingTimeText
        subtitle
        tags
        title
      }
    }
  }
`
