import React from "react"
import { Heading, Text, Image, Box, Link as A } from "theme-ui"
import { Link } from "gatsby"
import slugify from "slugify"

import TagsRow from "./TagsRow"
import CardBox from "./CardBox"
import AuthorBadge from "./AuthorBadge"
import { CLOUDINARY_BASE } from "../constants"

const FeaturedArticle = ({ data }) => {
  const { tags, title, desc, author, createdAt, image } = data

  const headingColor = "text"

  return (
    <A
      as={Link}
      to={`/posts/${slugify(title, { lower: true })}`}
      sx={featuredContainer}
    >
      <Box
        sx={{
          padding: [3, 4],
          position: ["static", "static", "absolute"],
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          height: "100%",
          paddingLeft: [3, 4, 4, 4, 5],
        }}
      >
        <CardBox
          style={{
            marginBottom: ["-81px", "-81px", 0],
            position: "relative",
            zIndex: 2,
          }}
        >
          <TagsRow tags={tags} />
          <Heading as="h2" sx={{ color: headingColor, fontSize: [5] }}>
            {title}
          </Heading>
          <Text variant="light" sx={{ fontSize: [1] }}>
            {desc}
          </Text>
          <AuthorBadge author={author} createdAt={createdAt}></AuthorBadge>
        </CardBox>
      </Box>
      <Image
        sx={featuredImageStyle}
        alt={title}
        src={`${CLOUDINARY_BASE}/${image[0].cloudinaryId}`}
      />
    </A>
  )
}

export default FeaturedArticle

const featuredContainer = {
  display: "block",
  position: "relative",
  textDecoration: "none",
}

const featuredImageStyle = {
  maxHeight: [600, 600, 600, 600, 800],
  width: "100%",
  padding: [2, 2, 2, 4],
  objectFit: "cover",
}
